import React, { ReactElement } from 'react'
import { Route } from 'react-router-dom'
import Landing from 'routes'
import ParkingActivate from 'routes/parking/id/activate'
import Parking from 'routes/parking'
import { Routes } from 'utilities/destinations'
import ParkingActivateForce from 'routes/parking/id/activate/force'
import ParkingActivateCompleted from 'routes/parking/id/activate/completed'
import ParkingReceipt from 'routes/parking/id/receipt'

function componentForRoute(route: Routes): ReactElement {
  switch (route) {
    case Routes.landing:
      return <Route path={route} element={<Landing />} key={route} />
    case Routes.parking:
      return <Route path={route} element={<Parking />} key={route} />
    case Routes.parkingActivate:
      return <Route path={route} element={<ParkingActivate />} key={route} />
    case Routes.parkingActivateCompleted:
      return <Route path={route} element={<ParkingActivateCompleted />} key={route} />
    case Routes.parkingActivateForce:
      return <Route path={route} element={<ParkingActivateForce />} key={route} />
    case Routes.parkingReceipt:
      return <Route path={route} element={<ParkingReceipt />} key={route} />
  }
}

export default componentForRoute

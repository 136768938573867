interface EnvironmentConfig {
  BASE_API_URL: string;
}

const ENV = process.env.REACT_APP_ENVIRONMENT || 'development'

const configs: Record<string, EnvironmentConfig> = {
  development: {
    BASE_API_URL: 'https://mlb.dev.inflowservice.com'
  },
  staging: {
    BASE_API_URL: 'https://mlb.staging.inflowservice.com'
  },
  production: {
    BASE_API_URL: 'https://mlb.prod.inflowservice.com'
  }
}

const config: EnvironmentConfig = configs[ENV] || configs.development

export const BASE_API_URL = config.BASE_API_URL
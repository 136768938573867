import React, { PropsWithChildren } from 'react'
import { Button, ButtonProps } from 'react-aria-components'
import { classNames } from 'utilities/etc'

interface Props extends ButtonProps, React.RefAttributes<HTMLButtonElement> {
  buttonType: 'primary' | 'secondary'
}

const TTButton: React.FC<PropsWithChildren<Props>> = ({ buttonType, ...props }) => {
  return (
    <Button
      {...props}
      className={classNames(
        'react-aria-Button',
        buttonType === 'primary' ? 'primary-button' : 'secondary-button'
      )}
    >
      {props.children}
    </Button>
  )
}

export default TTButton

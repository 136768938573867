import React from 'react'
import styles from './FourOhFour.module.css'

const FourOhFour: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1>Not Found</h1>
      <p>We could not find the page that you were looking for</p>
    </div>
  )
}

export default FourOhFour
import React, { useContext } from 'react'
import styles from './Logo.module.css'
import { ThemeContext } from 'App'

interface Props {
  size: 'small' | 'large'
  hideIfNoLogo?: boolean
}

export const Logo: React.FC<Props> = ({ size, hideIfNoLogo }) => {
  const skin = useContext(ThemeContext).skin
  if (!skin?.logoURL) return !hideIfNoLogo && <h1>Welcome to the Tap ’n Park Experience</h1>
  return <img src={skin.logoURL} className={size === 'small' ? styles.logoSmall : styles.logoLarge} />
}

export const TeamLogo: React.FC = () => {
  const skin = useContext(ThemeContext).skin
  if (!skin?.logoURL && !skin?.logo2URL) return <></>
  return <img src={skin?.logo2URL || skin.logoURL} className={styles.teamLogo} />
}

import React from 'react'
import TTLinkButton from 'globalComponents/fields/TTLinkButton'
import styles from './OnPageError.module.css'
import destinations from 'utilities/destinations'

interface Props {
  error: Error
  backToParkingPasses?: boolean
}

const OnPageError: React.FC<Props> = props => {
  return (
    <section className={styles.onPageErrorSection}>
      <h1>{props.error.name}</h1>
      <p>{props.error.message}</p>
      { props.backToParkingPasses && <TTLinkButton buttonType='secondary' href={destinations.parking.route()}>Go back to my passes</TTLinkButton> }
    </section>
  )
}

export default OnPageError
import React from 'react'
import packageJson from '../../package.json'

const Footer: React.FC = () => {
  const termsOfUseHref = 'https://www.mlb.com/app/ballpark/official-information/terms-of-use'
  const privacyPolicyHref = 'https://www.mlb.com/app/ballpark/official-information/privacy-policy'

  return (
    <footer>
      <div>
        <a href={privacyPolicyHref} target='_blank' rel='noreferrer'>Privacy Policy</a>
        <span>|</span>
        <a href={termsOfUseHref} target='_blank' rel='noreferrer'>Terms of Use</a>
      </div>
      <span className='version-number'>Version {packageJson.version}</span>
    </footer>
  )
}

export default Footer
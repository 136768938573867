import { useNavigate } from 'react-router-dom'
import { useAsync, useLocalStorage } from 'react-use'
import LocalStorageKeys from 'utilities/LocalStorageKeys'
import * as api from 'utilities/api'
import { MLBSkinMini, MLBTicket, PlateObject } from 'utilities/types'

const useSkinAndTickets = (csrfToken?: string) => {
  const navigate = useNavigate()
  const savedToken = localStorage.getItem(LocalStorageKeys.mlbSessionToken)
  const savedCSRF = localStorage.getItem(LocalStorageKeys.csrfToken)
  const [savedSkin, setSkin] = useLocalStorage<MLBSkinMini>(LocalStorageKeys.skin)
  const [savedPlates, setPlates] = useLocalStorage<PlateObject[]>(LocalStorageKeys.plates)

  const async = useAsync(async() => {
    let token = savedToken
    let skin = savedSkin
    let plates = savedPlates

    if (csrfToken && csrfToken != savedCSRF) {
      localStorage.clear()
      delete window.authToken
      localStorage.setItem(LocalStorageKeys.csrfToken, csrfToken)
      const welcomeResp = await api.v1.welcome(csrfToken)

      token = welcomeResp.mlbSessionToken
      skin = welcomeResp.skin
      plates = welcomeResp.plates

      localStorage.setItem(LocalStorageKeys.mlbSessionToken, welcomeResp.mlbSessionToken)
      window.authToken = token
      setSkin(skin)
      setPlates(plates)
      navigate(`?token=${token}`, { replace: true })
    }

    let tickets: MLBTicket[] = []
    if (token || window.authToken) {
      tickets = await api.v1.tickets()
    }

    return { skin, plates, tickets }
  })

  // Pull values from localStorage to avoid seeing default version these styles
  if (savedSkin) {
    const body = document.body
    body.style.setProperty('--primary', savedSkin.colorPrimary)
    body.style.setProperty('--secondary', savedSkin.colorSecondary)
    body.style.setProperty('--onPrimary', savedSkin.colorSecondary)
    body.style.setProperty('--onSecondaryHigh', savedSkin.colorPrimary)
    body.style.setProperty('--onSecondaryLow', savedSkin.colorNeutral)
    body.style.setProperty('--neutral', savedSkin.colorNeutral)
    body.style.setProperty('--onNeutral', savedSkin.colorOnNeutral)
    body.style.setProperty('--selected', savedSkin.colorPrimary)
  }

  return async
}

export default useSkinAndTickets
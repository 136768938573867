import React, { PropsWithChildren, ReactNode } from 'react'
import { Button, Dialog, Heading, Modal, ModalOverlayProps, PressEvent } from 'react-aria-components'
import TTButton from './fields/TTButton'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { ReactComponent as Alert } from 'assets/icons/alert.svg'

interface FooterButtonProps {
  copy: string,
  action: (e: PressEvent) => void,
}

type Props = ModalOverlayProps & {
  title: string
  titleStyle?: 'error' | 'caution' | 'normal'
  isDisabled?: boolean
  customTitleIcon?: ReactNode
  footerButton?: FooterButtonProps
  closeButtonCopy?: string
}

export const TTModal: React.FC<PropsWithChildren<Props>> = props => {
  const showFooterButton = !!props.footerButton
  const closeCopy = props.closeButtonCopy || 'Close'

  return <Modal {...props} isDismissable={props.isDisabled ? false : props.isDismissable || true }>
    <Dialog>
      { ({close}) =>
        <>
          { header(close) }
          <section className='modal-body'>
            { props.children }
          </section>
          <section className='modal-footer'>
            <TTButton buttonType={showFooterButton ? 'secondary' : 'primary'} onPress={close} isDisabled={props.isDisabled}>{closeCopy}</TTButton>
            { showFooterButton &&
              <TTButton buttonType='primary' onPress={props.footerButton?.action} isDisabled={props.isDisabled}>
                { props.footerButton?.copy }
              </TTButton>
            }
          </section>
        </>
      }
    </Dialog>
  </Modal>

  function header(onPress?: ((e: PressEvent) => void)) {
    const CloseButton: React.FC = () => <Button
      onPress={onPress}
      className='modal-close'
      isDisabled={props.isDisabled}
    >
      <Close/>
    </Button>

    switch(props.titleStyle) {
      case 'caution':
        return <section className='modal-caution-header'>
          <span className='caution-icon'>{ props.customTitleIcon || <Alert/> }</span>
          <Heading slot='title'>{ props.title }</Heading>
          <CloseButton/>
        </section>
      case 'error':
        return <section className='modal-error-header'>
          <span className='alert-icon'>{ props.customTitleIcon || <Alert/> }</span>
          <Heading slot='title'>{ props.title }</Heading>
          <CloseButton/>
        </section>
      default:
        return <section className='modal-header'>
          <Heading slot='title'>{ props.title }</Heading>
          <CloseButton/>
        </section>
    }
  }
}
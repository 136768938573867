import React from 'react'
import { ProgressBar } from 'react-aria-components'

const TTLoader: React.FC = () => {
  const center = 16
  const strokeWidth = 4
  const r = 16 - strokeWidth
  const c = 2 * r * Math.PI

  return (
    <ProgressBar aria-label='Loading…' isIndeterminate>
      <svg
        width={64}
        height={64}
        viewBox='0 0 32 32'
        fill='none'
        strokeWidth={strokeWidth}
      >
        <circle
          cx={center}
          cy={center}
          r={r}
          className='fill'
          stroke='var(--onNeutral)'
          opacity={.5}
        />
        <circle
          cx={center}
          cy={center}
          r={r}
          className='fill'
          stroke='var(--primary)'
          strokeDasharray={`${c} ${c}`}
          strokeDashoffset={c - 20 / 100 * c}
          opacity={.5}
          strokeLinecap='round'
        />
        <circle
          cx={center}
          cy={center}
          r={r}
          className='fill'
          stroke='var(--primary)'
          strokeDasharray={`${c} ${c}`}
          strokeDashoffset={c - 30 / 100 * c}
          strokeLinecap='round'
        />
      </svg>
    </ProgressBar>
  )
}

export default TTLoader
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { formatDate, formatDateForReceipt, formatTime } from 'utilities/etc'
import styles from './Receipt.module.css'
import { useAsync } from 'react-use'
import * as api from 'utilities/api'
import TTLoader from 'globalComponents/TTLoader'
import OnPageError from 'globalComponents/errors/OnPageError'
import TTLinkButton from 'globalComponents/fields/TTLinkButton'
import destinations from 'utilities/destinations'
import { TicketsContext } from 'App'
import { ReceiptError, ReceiptFailReason } from 'utilities/ExpectedErrors'

const Receipt: React.FC<{origin: 'activate' | 'passes'}> = props => {
  const { barcodeNum } = useParams()
  const tickets = useContext(TicketsContext).tickets
  const ticket = tickets.find(t => t.barcode === barcodeNum)
  const async = useAsync(() => api.v1.receipt({ barcode: barcodeNum }))

  if (async.loading) return <TTLoader/>
  if (async.error) {
    // If no ticketID, an error is thrown by api.v1.receipt before the API is called.
    return <OnPageError error={async.error} backToParkingPasses/>
  }

  if (!ticket) {
    const error = new ReceiptError(ReceiptFailReason.invalidTicket, barcodeNum)
    return <OnPageError error={error} backToParkingPasses/>
  }

  const receipt = async.value!
  const header = props.origin === 'activate'
    ? 'Parking Pass Successfully Activated. You’re All Set!'
    : 'Parking Pass Receipt'

  return (
    <>
      <h1>{header}</h1>
      <div className={styles.time}>
        <h2 className={styles.label}>Game time</h2>
        <p className={styles.timeValue}>{formatTime(ticket.eventStartTime)}</p>
        <p className={styles.date}>{formatDate(ticket.eventStartTime)}</p>
      </div>
      <div>
        <h2 className={styles.label}>Location</h2>
        <p className={styles.field}>{receipt.lotName || receipt.locationName}</p>
      </div>
      <div>
        <h2 className={styles.label}>Vehicle Plate</h2>
        <p className={styles.field}>{receipt.plate}</p>
      </div>
      <div>
        <h2 className={styles.label}>Ticket Number</h2>
        <p className={styles.field}>{receipt.barcode}</p>
      </div>
      <div>
        <h2 className={styles.label}>Activation Date</h2>
        <p className={styles.field}>{formatDateForReceipt(receipt.startTime)}</p>
      </div>
      <p className='as-h1' style={{marginBottom: '.75rem'}}>Thank you. Enjoy the ballgame!</p>
      <p style={{marginTop: 0}}>Please note: The parking lot closes one hour <br/>after the game ends.</p>
      {
        tickets.length > 1
        && <TTLinkButton buttonType='primary' href={destinations.parking.route()}>
          Back to my passes
        </TTLinkButton>
      }
    </>
  )
}

export default Receipt

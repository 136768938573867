import React, { PropsWithChildren } from 'react'
import { Link, LinkProps } from 'react-aria-components'
import { classNames } from 'utilities/etc'

interface Props extends LinkProps, React.RefAttributes<HTMLAnchorElement> {
  buttonType: 'primary' | 'secondary'
}

// Need to a link for accessibility reasons, but still want it to "look" like a button? 
const TTLinkButton: React.FC<PropsWithChildren<Props>> = ({ buttonType, ...props }) => {
  return (
    <Link
      {...props}
      className={classNames(
        'react-aria-Button',
        buttonType === 'primary' ? 'primary-button' : 'secondary-button',
      )}
    >
      {props.children}
    </Link>
  )
}

export default TTLinkButton

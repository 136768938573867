import React, { createContext, useMemo } from 'react'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import { $enum } from 'ts-enum-util'
import componentForRoute from 'utilities/componentForRoute()'
import { Routes as IPCRoutes } from 'utilities/destinations'
import useRootBackgroundColor from 'utilities/hooks/useRootBackgroundColor()'
import FourOhFour from 'globalComponents/FourOhFour'
import Footer from 'globalComponents/Footer'
import FloatingHelpButton from 'globalComponents/FloatingHelpButton'
import useSkinAndTickets from 'utilities/hooks/useSkinAndTickets()'
import TTLoader from 'globalComponents/TTLoader'
import { MLBSkinMini, MLBTicket, PlateObject } from 'utilities/types'
import Navigation from 'globalComponents/Navigation'
import ErrorModalProvider from 'globalComponents/errors/ErrorModalProvider'
import * as Sentry from '@sentry/react'

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'development'
const isProd = ENVIRONMENT === 'production'

Sentry.init({
  dsn: 'https://10f626e2edc245ec6c797db79ec22191@o4507692276514816.ingest.us.sentry.io/4507692468600832',
  environment: ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: isProd ? 0.1 : 1.0, // 10% in production, 100% otherwise
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    // regext to match all subdomains that starts with  https://*.tapntailgate.com
    /^https:\/\/.*\.tapntailgate\.com/,
    // regext to match all subdomains that starts with  https://*.ipctg.com
    /^https:\/\/.*\.ipctg\.com/,
    // regext to match all subdomains that starts with  https://*.testnpark.com
    /^https:\/\/.*\.testnpark\.com/
  ],
  // Session Replay
  replaysSessionSampleRate: isProd ? 0.1 : 1.0, // 10% in production, 100% otherwise
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

interface ThemeContextType {
  skin?: MLBSkinMini,
  plates: PlateObject[]
}

interface TicketsContextType {
  tickets: MLBTicket[]
}

export const ThemeContext = createContext<ThemeContextType>({ plates: [] })
export const TicketsContext = createContext<TicketsContextType>({ tickets: [] })

function App() {
  useRootBackgroundColor()

  const [query] = useSearchParams()
  const token = query.get('token')
  if (token) { window.authToken = token } // Backup token
  const skinAndTicketsAsync = useSkinAndTickets(query.get('csrf') || '')

  const themeContextValue: ThemeContextType = useMemo(() => ({
    skin: skinAndTicketsAsync.value?.skin,
    plates: skinAndTicketsAsync.value?.plates || []
  }), [skinAndTicketsAsync.value?.skin, skinAndTicketsAsync.value?.plates])

  const ticketsContextValue: TicketsContextType = useMemo(() => ({
    tickets: skinAndTicketsAsync.value?.tickets || []
  }), [skinAndTicketsAsync.value?.tickets])

  if (skinAndTicketsAsync.loading) return <TTLoader/>
  if (skinAndTicketsAsync.error) throw skinAndTicketsAsync.error // We want to show <ErrorFallback/> if this ever happens

  return (
    <ErrorModalProvider>
      <ThemeContext.Provider value={themeContextValue}>
        <TicketsContext.Provider value={ticketsContextValue}>
          <Navigation/>
          <main id='content'>
            <Routes>
              {$enum(IPCRoutes).map(componentForRoute)}
              {/* catch all 404 */}
              <Route path='*' element={<FourOhFour />} />
            </Routes>
          </main>
        </TicketsContext.Provider>
        <FloatingHelpButton/>
        <Footer/>
      </ThemeContext.Provider>
    </ErrorModalProvider>
  )
}

export default App
